<template>
  <a-form-model-item prop="code">
    <a-input
      v-model.trim="code"
      size="large"
      :maxLength="6"
      placeholder="请输入验证码"
      style="width: 186px; margin-right: 10px;"
    >
      <a-icon
        slot="prefix"
        type="mail"
      />
    </a-input>
    <a-button
      type="primary"
      size="large"
      v-click.timeout="3"
      @click="getVcodeClick"
      style="width:144px;"
      :disabled="!!timer"
    >
      {{countDown}}
    </a-button>
  </a-form-model-item>
</template>
<script>
export default {
  props: {
    validate: { type: Function }
  },
  data() {
    return {
      code: "",
      timer: null,
      countDown: "发送短信验证码"
    };
  },
  watch: {
    code(val) {
      this.$emit("input", val);
    }
  },
  beforeDestroy() {
    this.reset();
  },
  methods: {
    getVcodeClick() {
      this.validate().then(async ({ bizType, mobile }) => {
        if (this.timer) return;
        // 验证码倒计时
        let step = 60;
        this.timer = setInterval(() => {
          if (step < 1) {
            this.reset();
          } else {
            this.countDown = `已发送(${step}s)`;
          }
          step -= 1;
        }, 1000);
        // 发送验证码
        const res = await this.api.service.uac_oapi_msg_portalSendMsg(
          {
            bizType,
            mobile,
            source: 4
          },
          {}
        );
        await this.api.toast(res).catch(() => {
          this.reset();
        });
      });
    },
    // 重置
    reset() {
      clearInterval(this.timer);
      this.countDown = "发送短信验证码";
      this.timer = null;
    }
  }
};
</script>
